import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export interface YesNoAlertDialogProps {
  title: string;
  message: string;
  acceptPrompt: string;
  rejectPrompt: string;
  open: boolean;
  width?: string;
  onReject: () => void;
  onAccept: () => void;
}

const YesNoAlertDialog: React.FC<YesNoAlertDialogProps> = (props) => {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialog-paper': { width: props.width ?? '100%', maxWidth: '100%' } }}
      >
        <Box sx={{ p: 1, py: 1.5 }}>
          <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{props.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={props.onAccept}>
              {props.acceptPrompt}
            </Button>
            <Button variant="contained" onClick={props.onReject} autoFocus>
              {props.rejectPrompt}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default YesNoAlertDialog;
