import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ActionBar from 'components/action-bar/ActionBar';
import MainCard from 'components/MainCard';
import TitleAndCaptionRegion from 'components/TitleAndCaption/TitleAndCaptionRegion';
import dayjs from 'dayjs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useEffect, useState } from 'react';
import { ApiResponse, ApiResponseNoData } from 'types/api';
import { GeneratedPublicApiKey } from 'types/public-api-keys';
import axiosServices from 'utils/api/axiosServices';
import { ContentCopy, Delete as DeleteIcon, Refresh } from '@mui/icons-material';
import LoadingButton from 'components/@extended/LoadingButton';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import LightTooltip from 'components/LightToolTip';
import YesNoAlertDialog from 'components/YesNoAlertDialog';
import useEnv from 'hooks/useEnv';

// TODO : Move to API layer
const getApiKey = async () => {
  const request: string = `public-api-keys`;
  const response = await axiosServices.get<ApiResponse<GeneratedPublicApiKey>>(request);
  return response.data.data;
};

// TODO : Move to API layer
const deleteApiKey = async () => {
  const request: string = 'public-api-keys';
  const response = await axiosServices.delete<ApiResponseNoData>(request);
  return response.data;
};

// TODO : Move to API layer
const generateApiKey = async () => {
  const request: string = 'public-api-keys/generate';
  const response = await axiosServices.post<ApiResponse<GeneratedPublicApiKey>>(request);
  return response.data.data;
};

// TODO : Move to API layer
const regenerateApiKey = async () => {
  const request: string = 'public-api-keys/regenerate';
  const response = await axiosServices.post<ApiResponse<GeneratedPublicApiKey>>(request);
  return response.data.data;
};

const RestApiKeysPage = () => {
  useDocumentTitle('REST Api Keys');
  const env = useEnv();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.only('xs'));
  const [apiKey, setApiKeys] = useState<GeneratedPublicApiKey>();
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [showRegenerateDialog, setShowRegenerateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    setLoading(true);

    getApiKey()
      .then((data) => {
        setApiKeys(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const hasExistingApiKey = !!apiKey;

  const handleDeleteApiKey = async () => {
    try {
      setLoading(true);
      setShowDeleteDialog(false);
      setShowRegenerateDialog(false);

      await deleteApiKey();

      setApiKeys(undefined);
      setGenerated(false);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateApiKey = async () => {
    try {
      setLoading(true);
      setShowDeleteDialog(false);
      setShowRegenerateDialog(false);

      const data = hasExistingApiKey ? await regenerateApiKey() : await generateApiKey();
      setApiKeys(data);
      setGenerated(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClickGenerateApiKey = () => {
    if (!hasExistingApiKey) {
      handleGenerateApiKey();
      return;
    }

    setShowRegenerateDialog(true);
  };

  const handleClickDeleteApiKey = () => {
    setShowDeleteDialog(true);
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);

    dispatch(
      openSnackbar({
        open: true,
        message: 'Your API Key has been copied to the clipboard.',
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
  };

  return (
    <Stack direction="column">
      <ActionBar
        hideBackNavigation
        mainRegion={
          <TitleAndCaptionRegion
            titleFontVariant="h5"
            title="API Keys"
            caption="Here you can generate API keys to access our REST API for integrations"
          />
        }
      />
      <Stack>
        <MainCard title={null}>
          <Stack>
            <YesNoAlertDialog
              open={showRegenerateDialog}
              onReject={() => setShowRegenerateDialog(false)}
              onAccept={() => handleGenerateApiKey()}
              title="Are you sure?"
              width="40%"
              message="If you re-generate the API key any requests to the API that are using the old key will fail."
              acceptPrompt="Yes"
              rejectPrompt="No"
            />
            <YesNoAlertDialog
              open={showDeleteDialog}
              onReject={() => setShowDeleteDialog(false)}
              onAccept={() => handleDeleteApiKey()}
              title="Are you sure?"
              width="40%"
              message="This will permanently delete your current api key any requests to the API using the key will fail."
              acceptPrompt="Yes"
              rejectPrompt="No"
            />
          </Stack>
          <Stack direction="column" spacing={2}>
            <Typography variant="body1">
              To access our integrations REST API you will need to generate an API key here. The key will need to be provided in the
              X-API-KEY header for any requests to our public API.
            </Typography>
            <Typography variant="body1">
              You can find the documentation for the public API here:{' '}
              <a href={env.REACT_APP_PUBLIC_API_DOCS_URL} target="_blank" rel="noreferrer">
                {env.REACT_APP_PUBLIC_API_DOCS_URL}
              </a>
            </Typography>

            <Stack direction="row">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {!matchesXs && <TableCell sx={{ width: '15%' }}>Created On</TableCell>}
                      <TableCell>API Key</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography variant="body1" color="textSecondary">
                            Loading...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {!loading && !hasExistingApiKey && (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography variant="body1" color="textSecondary">
                            <i>You do not have an active API Key. Use the generate button to create one.</i>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {!loading && hasExistingApiKey && (
                      <TableRow>
                        {!matchesXs && (
                          <TableCell>
                            <Typography variant="subtitle1" noWrap>
                              {dayjs(apiKey.createdAt).format('DD/MM/YYYY HH:mm')}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell>
                          <Stack direction="column">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle1" noWrap>
                                {apiKey.apiKey}
                              </Typography>
                              {generated && (
                                <LightTooltip title="Copy to clipboard" placement="top">
                                  <IconButton onClick={() => handleCopyToClipboard(apiKey.apiKey)}>
                                    <ContentCopy />
                                  </IconButton>
                                </LightTooltip>
                              )}
                            </Stack>
                            {generated && (
                              <Typography variant="body2" color="textSecondary">
                                Make sure to copy your API key and save it somewhere secure as it will not be shown again.
                              </Typography>
                            )}

                            {!generated && (
                              <Typography variant="body2" color="textSecondary">
                                Your API Key is masked for security. You will need to regenerate a new API key if you do not have access to
                                this one.
                              </Typography>
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack
              direction={matchesXs ? 'column' : 'row'}
              spacing={2}
              justifyContent={matchesXs ? 'center' : 'flex-end'}
              flexWrap={'wrap'}
            >
              {hasExistingApiKey && (
                <Stack>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleClickDeleteApiKey}
                  >
                    Delete API Key
                  </LoadingButton>
                </Stack>
              )}
              <Stack>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  color="success"
                  startIcon={<Refresh />}
                  onClick={hasExistingApiKey ? handleClickGenerateApiKey : handleGenerateApiKey}
                >
                  {hasExistingApiKey ? 'Re-generate API Key' : 'Generate API Key'}
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </MainCard>
      </Stack>
    </Stack>
  );
};

export default RestApiKeysPage;
